import React, { useState, useRef } from "react"
import { SubmitButton } from "./Buttons"
import ReCAPTCHA from "react-google-recaptcha"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const CareersForm = props => {
  const [details, setDetails] = useState({
    name: "",
    email: "",
    message: "",
    role: "",
  })

  const [thanks, setThanks] = useState(false)

  const [errors, setErrors] = useState({
    errorName: "",
    errorEmail: "",
    errorCaptcha: "",
  })

  const reCaptcha = useRef()

  const handleChange = e => {
    setDetails({ ...details, [e.target.name]: e.target.value })
    e.target.files && setDetails({ ...details, upload: e.target.files[0] })
    setErrors({ errorName: "", errorEmail: "", errorCaptcha: "" })
  }

  function handleValidation() {
    let formIsValid = true
    let name = details.name
    let email = details.email
    let lastAtPos = details.email.lastIndexOf("@")
    let lastDotPos = details.email.lastIndexOf(".")
    const recaptchValue = reCaptcha.current.getValue()

    if (!recaptchValue) {
      formIsValid = false
      setErrors({ errorCaptcha: "Please complete security" })
    }

    if (!name) {
      formIsValid = false
      setErrors({ errorName: "This cannot be empty" })
    }

    if (!email) {
      formIsValid = false
      setErrors({ errorEmail: "This cannot be empty" })
    } else if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        details.email.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        details.email.length - lastDotPos > 2
      )
    ) {
      formIsValid = false
      setErrors({ errorEmail: "Email is not valid" })
    }

    return formIsValid
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (handleValidation()) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        body: encode({ "form-name": "careers", ...details }),
      })
        .then(() => setThanks(!thanks))
        .catch(error => alert(error))
    }
  }

  return (
    <div className="layout-box">
      {thanks ? (
        <div style={{ margin: "auto" }}>
          <h2>Thanks!</h2>
          <br />
          <h2>Someone will be in touch.</h2>
        </div>
      ) : (
        <form
          data-netlify="true"
          name="careers"
          onSubmit={handleSubmit}
          className="address-box"
        >
          <input type="hidden" name="form-name" value="careers" />
          <div className="input-box">
            <input
              type="text"
              name="name"
              placeholder="Name:"
              onChange={handleChange}
            />
            <label>{errors.errorName}</label>
          </div>
          <div className="input-box">
            <input
              type="text"
              name="email"
              placeholder="Email:"
              onChange={handleChange}
            />
            <label>{errors.errorEmail}</label>
          </div>
          <div className="input-box">
            <input
              type="text"
              name="role"
              placeholder="Role:"
              onChange={handleChange}
            />
            <label>{errors.errorEmail}</label>
          </div>
          {/* <div className="input-box">
            <input
              type="file"
              name="upload"
              placeholder="Upload CV:"
              onChange={handleChange}
            />
          </div> */}
          <div className="input-box" style={{ gridColumn: "1 / 3" }}>
            <textarea
              type="text"
              name="message"
              placeholder="Explain why you would be great for this role?"
              rows="5"
              onChange={handleChange}
              style={{ width: "95%" }}
            />
          </div>
          <div className="input-box end-grid recaptcha">
            <ReCAPTCHA
              sitekey={process.env.SITE_RECAPTCHA_KEY}
              ref={reCaptcha}
            />
            <label>{errors.errorCaptcha}</label>
          </div>
          <div className="input-box end-grid">
            <SubmitButton>Submit</SubmitButton>
          </div>
        </form>
      )}
    </div>
  )
}

export default CareersForm
