import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import ShareBanner from "../components/UI/share-banner"
import { PopButton } from "../components/UI/Buttons"
import CareersForm from "../components/UI/contact-careers-form"

const IntroBox = styled.div`
  padding: 2em;

  h1 {
    text-align: center;
  }

  h5 {
    margin: 0 auto;
    width: 80%;
  }
`

const JobGrid = styled.div`
  display: grid;
  margin: 0 10%;
  grid-template-columns: 1fr 1fr;
  gap: 5%;

  h3 {
    margin: 12px 0;
  }

  .job-card {
    display: block;
    padding: 2px 2em 0 2em;
    filter: var(--tuva-drop-shadow-filter);
    background-color: #fff;

    .black {
      margin: 0 0 1em auto;
      padding: 2px 32px;
    }
  }

  @media (max-width: 1024px) {
    display: block;

    .job-card {
      margin: 1em 0;
    }
  }
`

const Careers = ({ data }) => {
  const { contact, statement, title } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  const job = data.allMarkdownRemark.edges.filter(
    edge => edge.node.frontmatter.visible
  )

  //Create a job card for each open position
  const JobItem = job.map(({ node }) => {
    const { role, salary, requirements } = node.frontmatter
    const excerpt = node.excerpt
    const slug = node.fields.slug
    const salVisible = salary.visible //Check if the requirements should be visible.
    const reqVisible = requirements.visible //Check if the requirements should be visible.

    return (
      <div className="job-card" key={role}>
        <h4>{role}</h4>

        <div className="requirements">
          <h3 style={salVisible ? { display: "inherit" } : { display: "none" }}>
            {salary.amount}
          </h3>
          <h3 style={reqVisible ? { display: "inherit" } : { display: "none" }}>
            {requirements.details}
          </h3>
        </div>

        <p dangerouslySetInnerHTML={{ __html: excerpt }} />
        <div style={{ display: "flex" }}>
          <PopButton linkRef={slug}>More Info</PopButton>
        </div>
      </div>
    )
  })

  return (
    <Layout>
      <IntroBox style={{ paddingTop: "5em" }}>
        <h5>{statement}</h5>
      </IntroBox>
      <IntroBox>
        <h1>{title}</h1>
      </IntroBox>
      <JobGrid>{JobItem}</JobGrid>
      <IntroBox>
        <h1>{contact.title}</h1>
        <h5>{contact.body}</h5>
      </IntroBox>
      <CareersForm />
      <ShareBanner />
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "careers" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              statement
              contact {
                title
                body
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { cms: { eq: "job" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          excerpt
          frontmatter {
            title
            role
            salary {
              amount
              visible
            }
            visible
            date
            requirements {
              details
              visible
            }
          }
        }
      }
    }
  }
`

export default Careers
